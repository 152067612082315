import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#9AC39C',
      main: '#418543',
      dark: '#1B381C',
    },
    secondary: {
      light: '#CCC5E8',
      main: '#7C759C',
      dark: '#5F4DAC',
    },
  },
  typography: {
    h1: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    h2: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    h3: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    h4: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    h6: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    subtitle1: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    subtitle2: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    body1: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    body2: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    button: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    caption: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    overline: {
      fontFamily: ['Consolas', 'Monaco', 'monospace'].join(','),
    },
    fontFamily: ['Consolas', '"Consolas"', 'Monaco', 'monospace'].join(','),
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiDropzoneArea: {
      text: {
        color: theme.palette.primary.main,
        fontSize: 20,
      },
      icon: {
        color: theme.palette.primary.main,
        height: 40,
      },
      successAlert: {
        backgroundColor: '#FAA',
        color: '#000',
      },
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Route path="/:page?" render={(props) => <App {...props} />} />
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
