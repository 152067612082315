import { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import Blurb from './components/blurb';
import FeaturedBlurb from './components/featuredBlurb';
import FurtherReading from './components/furtherReading';

import pluto_bicycle from './assets/img/pluto_bicycle.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#EEEEEB",
    minHeight: "100vh"
  },
  container: {
    padding: theme.spacing(2),
  },
  belowContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    color: "#969696",
  },
  highlight: {
    color: "#009900",
  },
  attribution: {
    color: "#D27800"
  },
  link: {
    color: "#0000E6"
  },
  featuredContainer: {
    minHeight: '500px'
  }
}));

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const welcome = "<p>Heyo! And, thank you for visiting.</p> <p>This is a collection of recent work by me - Reed Anderson</p>";

const projects = [

  {
    title: "Feb-2024:   Forest Monster",
    subtitle: "forestmonster",
    isLive: true,
    url: "www.forestmonster.io",
    displayUrl: "www.forestmonster.io",
    content: "<p>Hacker<br><br>- <strong>Takeaway</strong>: Web app using Serverless AI Pipeline<br>- <strong>Highlights</strong>: Custom Docker images hosted on AWS Lamdba, Custom Semi-Supervised GAN AI Model, Merging multiple data formats.<br>- <strong>Tech</strong>: Javascript, Python, Docker, MapBox, Tailwind CSS, AWS Lamdba</p>"
  },
  {
    title: "Jan-2024:   BlackBoiler.com",
    subtitle: "blackboiler",
    isLive: true,
    url: "www.blackboiler.com",
    displayUrl: "www.blackboiler.com",
    content: "<p>Full-Stack Software Engineer (May 2021 - Jan 2024)<br><br>- <strong>Takeaway</strong>: Rapid prototyping with legacy codebase.<br>- <strong>Highlights</strong>: Multi-tenant web application, Third-party integration, Troubleshooting.<br>- <strong>Tech</strong>: Javascript, Python, Node, React, Docker, Kubernetes</p>"
  },
  {
    title: "May-2021:   RedAppleArtStudio.com",
    subtitle: "red apple art studio",
    isLive: true,
    url: "reedtheengineer.com",
    displayUrl: "www.redappleartstudio.com [inactive]",
    content: "<p>Freelance commission for artist website.<br><br>- <strong>Takeaway</strong>: Beautiful site designed and delivered to client in two-weeks.<br>- <strong>Highlights</strong>: Dynamic Menu, Large media CMS, Blog.<br>- <strong>Tech</strong>: Netlify, React, NextJS, Material-UI.<br><br>PageSpeed Insights: 99 (Mobile) / 100 (Desktop)<br><br><br></p>"
  },
  {
    title: "Feb-2021:   TheBluSaint.com",
    subtitle: "the blu saint",
    isLive: true,
    url: "reedtheengineer.com",
    displayUrl: "www.theblusaint.com [inactive]",
    content: "<p>Freelance commission for website.<br><br>- <strong>Takeaway</strong>: Reduced overhead from $60/month to $0/month.<br>- <strong>Highlights</strong>: Blog, eCommerce, Scheduling.<br>- <strong>Tech</strong>: Netlify CMS, React, Gatsby, Material-UI.<br><br>PageSpeed Insights: 70 (Mobile) / 96 (Desktop)<br><br><br></p>",
  },
  {
    title: "Nov-2020:   GoodGlobe.org",
    subtitle: "goodglobe",
    isLive: true,
    url: "master--lucid-golick-8b9883.netlify.app",
    displayUrl: "https://master--lucid-golick-8b9883.netlify.app [deprecated demo]",
    content: "<p>Minimum Viable Product for aspiring nonprofit.<br><br>- <strong>Takeaway</strong>: Secured support for 1.5 personnel for ongoing development.<br>- <strong>Highlights</strong>: SPA, Blog, Payments.<br>- <strong>Tech</strong>: &nbsp;Netlify, React, Stripe, AWS Lambda, AWS API Gateway, DynamoDB, Material-UI.<br><br>PageSpeed Insights: 65 (Mobile) / 75 (Desktop)<br><br><br></p>",
    pdf: "https://github.com/reedanders/goodglobe-client",
    pdfTitle: "github.com/reedanders/goodglobe-client"
  },
  {
    title: "Sep-2020:   Tsuga",
    subtitle: "tsuga photogrammetry machine learning",
    isLive: true,
    url: "github.com/reedanders/pointnet_datasets",
    displayUrl: "https://github.com/reedanders/pointnet_datasets",
    content: "<p>SaaS product at intersection of ecology and deep learning.</p> <p>Academia wasn&apos;t a fit for me, so I left a PhD program in 2020. Onward! I continue doing similar work with Tsuga. &nbsp;</p> <p>- <strong>Takeaway</strong>: Innovative LiDAR processing as a service.<br>- <strong>Highlights</strong>: Read my colloquium below.<br>- <strong>Tech</strong>: AWS SageMaker, AWS Lambda, AWS API Gateway.</p>", 
    pdf: "https://tu-dresden.de/bu/umwelt/geo/ipf/photogrammetrie/ressourcen/dateien/forschung/publikationen/2019_anderson_deep-learning-for-segmentation-of-point-clouds-with-synthetic-vegetation/?lang=en",
    pdfTitle: "\"Deep Learning for Segmentation of Point Clouds with Synthetic Vegetation\" [pdf]"
  },   
  {
    title: "May-2017:   Linear Programming",
    subtitle: "linear programming",
    isLive: true,
    displayUrl: "www.github.com/reedanders",
    url: "www.github.com/reedanders/soilgenerate",
    content: "<p>Website and model for grassland restoration framework.<br><br>- <strong>Takeaway</strong>: Jumped from a B+ to an A with this gem.<br>- <strong>Highlights</strong>: SPA, Custom App Engine Install.<br>- <strong>Tech</strong>: &nbsp;Google App Engine, Simplex Algorithm, Bootstrap.</p>",
  }, 
  {
    title: "Jan-2017:   ICESat-2",
    subtitle: "icesat 2",
    isLive: true,
    url: "",
    content: "<p>Python implementation of NASA ICESAT-2 LiDAR Visualization.<br><br>- <strong>Takeaway</strong>: Fixed legacy code returned to us for refactor.<br>- <strong>Highlights</strong>: Numerical computing, Data visualization.<br>- <strong>Tech</strong>: &nbsp;Python, Numpy, MatPlotLib.</p>",
  }, 
  {
    title: "Jan-2015:   Pluto Bicycles",
    subtitle: "pluto bicycles",
    isLive: true,
    url: "",
    content: "<p>Sourcing bicycle prototype in Mainland China.<br><br>- <strong>Takeaway</strong>: First startup failed to retail custom bikes in US.<br>- <strong>Highlights</strong>: Hardware sourcing, Negotiation, Mandarin Chinese.<br>- <strong>Tech</strong>: &nbsp;None. That&rsquo;s why I became a software engineer.</p>",
    image: pluto_bicycle,
  }, 
  ];

// const slugToIndex = projects.reduce((hash, project, index) => {
//     hash[index] = project.subtitle.replace(/\s+/g,'-').toLowerCase();
//     return hash;
//   }
//   , {});

const indexToSlug = projects.reduce((hash, project, index) => {
    hash[project.subtitle.replace(/\s+/g,'-').toLowerCase()] = index;
    return hash;
  }
  , {});

function App(props) {
  const classes = useStyles();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [selected, setSelected] = useState(0);
  const { location, history } = props;
  const { hash } = location;

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (hash.substring(1) in indexToSlug) {
      setSelected(indexToSlug[hash.substring(1)]);
    } else {
      history.push("/")
    }
  }, [hash, history])

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid container item xs={12} sm={6}>

          <Grid item>
            <Blurb width={windowDimensions.width} title="Introduction">
              <Typography
                  dangerouslySetInnerHTML={{ __html: welcome }}
                  component="div"
                  variant="body2"
                />
            </Blurb>
          </Grid>

          <Grid item>
            <Blurb width={windowDimensions.width} title="Recent Work">
              {projects.map(( {title, subtitle}, index) => 
                <Typography variant="body2" component="p" key={index} gutterBottom>
                <Link href={`#${subtitle.replace(/\s+/g,'-').toLowerCase()}`} onClick={() => setSelected(index)} className={classes.link}>
                  {title}
                </Link>
                </Typography>
              )}
            </Blurb>
          </Grid>

        </Grid>

        <Grid item xs={12} sm={6} className={classes.featuredContainer}>
          <Blurb width={windowDimensions.width} title="">
            {projects.reduce((newDict, {title, url, displayUrl, content, pdf, pdfTitle, image}, index) => {
              return selected === index ? 
              <FeaturedBlurb title={title} url={url} displayUrl={displayUrl} key={index} image={image}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: content }}
                  component="div"
                  variant="body2"
                />
                <Typography
                  variant="body2"
                ><Link href={pdf} className={classes.link}>{pdfTitle}</Link></Typography>
              </FeaturedBlurb> 
              : newDict;
            }, {})
            }
          </Blurb>
        </Grid>

      </Grid>

      <Grid container className={classes.belowContainer}>
      <Grid item xs={12} sm={6}>
        <Blurb width={windowDimensions.width} title="Further Reading">
          <FurtherReading/>
        </Blurb>
      </Grid>
      </Grid>

    </div>
  );
}

export default App;
