import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  container: {
    padding: theme.spacing(2),
  },
  title: {
    color: "#969696",
  },
  highlight: {
    color: "#009900",
  },
  link: {
    color: "#0000E6"
  },
  attribution: {
    color: "#D27800",
  },
}));


function FeaturedBlurb(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.highlight} variant="body2" gutterBottom> 
        <Grid container spacing={1}>
          <Grid item><Link href="https://www.github.com/skogsmonster" className={classes.link}>[Current Github]</Link></Grid> 
          <Grid item><Link href="https://www.github.com/reedanders" className={classes.link}>[Academic Github]</Link></Grid>
          <Grid item><Link href="https://www.linkedin.com/in/reed-anders" className={classes.link}>[LinkedIn]</Link></Grid>  
          <Grid item><Link href="https://angel.co/u/reed-anderson" className={classes.link}>[WellFound]</Link></Grid> 
        </Grid>
      </Typography>
      <Typography className={classes.link} variant="body2" >
        <Link href="https://www.musicforprogramming.net/" className={classes.attribution}>[Design Inspiration]</Link>
      </Typography>
    </div>
  );
}

export default FeaturedBlurb;
