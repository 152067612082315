import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  container: {
    padding: theme.spacing(2),
  },
  title: {
    color: "#969696",
  },
  highlight: {
    color: "#009900",
  },
  attribution: {
    color: "#D27800",
  },
  link: {
    color: "#0000E6"
  },
  children: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(4),
  }
}));

const addDash = (text, width, remToPx) => {
  const textLength = remToPx * [...text].length;
  return `// ${text} ${"-".repeat((width - textLength)/remToPx)}`
}

function Blurb(props) {
  const classes = useStyles();
  const { width, title, children } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="body2">
        {width > 600 ? 
          addDash(title, width, 20) :
          addDash(title, width, 10)
        }
      </Typography>
      <div className={classes.children}>{children}</div>
    </div>
  );
}

export default Blurb;
