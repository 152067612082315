import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  container: {
    padding: theme.spacing(2),
  },
  title: {
    color: "#969696",
  },
  highlight: {
    color: "#009900",
  },
  link: {
    color: "#0000E6"
  },
  attribution: {
    color: "#D27800",
  },
  image: {
    maxHeight: "220px",
    width: "100%",
    paddingTop: theme.spacing(2),
  }
}));


function FeaturedBlurb(props) {
  const classes = useStyles();
  const { title, displayUrl, url, image, children } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.highlight} variant="body2" gutterBottom>
        {title}
      </Typography>
      <Typography className={classes.link} variant="body2" >
        <Link href={`https://${url}`} className={classes.link}>{displayUrl ? displayUrl : url}</Link>
      </Typography>
      <br/>
      <div className={classes.children}>{children}</div>
      {image ?
      <Grid container justify="center">
        <Grid item>
          <img src={image} alt="road bike on sidewalk" className={classes.image}/>
        </Grid>
      </Grid>
       : ""}
    </div>
  );
}

export default FeaturedBlurb;
